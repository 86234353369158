<script>
import TextField from '../inputs/text.vue'

export default {
  extends: TextField,
  props: {
    label: {
      type: String,
      default: 'First Name'
    },
    name: {
      type: String,
      default: 'first_name'
    },
    rules: {
      type: String,
      default: 'required|min:2'
    }
  }
}
</script>
